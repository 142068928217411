export default function ToggleAnalytics() {
  //  return null
  /*
  if (document?.cookie?.includes("ot-ga=true")) {
    return alert("Do analytics")
  } else {
    return alert("Do NOT track")
  }
  */

  if (document?.cookie?.includes("ot-ga=true")) {
    const currentScripts = [...document.querySelectorAll("script")]
    const gaCode = "G-04K9DVMVKY"

    if (
      !currentScripts.some(
        script =>
          script.src === `https://www.googletagmanager.com/gtag/js?id=${gaCode}`
      )
    ) {
      let scriptAsync = document.createElement("script")
      scriptAsync.src = `https://www.googletagmanager.com/gtag/js?id=${gaCode}`
      scriptAsync.async = true
      document.body.appendChild(scriptAsync)
    }

    if (!currentScripts.some(script => script.innerHTML.includes(gaCode))) {
      let scriptInner = document.createElement("script")
      scriptInner.innerHTML = `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('confg', '${gaCode}');
                `
      document?.body?.appendChild(scriptInner)
    }
  } else {
    const docScripts = [...document?.querySelectorAll("script")]

    const asyncScript = docScripts?.find(script => {
      const gaCode = "G-04K9DVMVKY"

      return (
        script.src === `https://www.googletagmanager.com/gtag/js?id=${gaCode}`
      )
    })
    if (asyncScript) {
      asyncScript.parentNode.removeChild(asyncScript)
    }

    const innerScript = docScripts?.find(script => {
      const gaCode = "G-04K9DVMVKY"
      return script.innerHTML.includes(gaCode)
    })

    if (innerScript) {
      innerScript.parentNode.removeChild(innerScript)
    }
  }
}
